<template>
  <b-modal
    id="modal-password"
    :title="$t('administration.change_password')"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    ok-variant="primary"
    cancel-variant="secondary"
    centered
    @ok="onSubmit"
  >
    <validation-observer ref="newPasswordValidation">
      <b-form>
        <b-form-group>
          <label for="password">{{ $t('user.password') }}</label>
          <validation-provider
            #default="{ errors }"
            :name="$t('user.password')"
            rules="required"
          >
            <b-input-group
              :class="errors.length > 0 ? 'is-invalid' : null"
              class="input-group-merge"
            >
              <b-form-input
                id="v-password-new-user"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType"
                class="form-control-merge"
                name="v-password-new-user"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <label for="password_confirm">{{
            $t('user.password_confirm')
          }}</label>
          <validation-provider
            #default="{ errors }"
            :name="$t('user.password_confirm')"
            :rules="'required|confirm:@' + $t('user.password')"
          >
            <b-input-group
              :class="errors.length > 0 ? 'is-invalid' : null"
              class="input-group-merge"
            >
              <b-form-input
                id="v-password-confirm-new-user"
                v-model="password_confirm"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType"
                class="form-control-merge"
                name="v-password-confirm-new-user"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import { mapActions } from 'vuex'
import i18n from '@/libs/i18n'

extend('confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Ошибка совпадения пароля',
})

export default {
  data() {
    return {
      required,
      password: '',
      password_confirm: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('users', ['UPDATE_USER']),
    onSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.newPasswordValidation.validate().then(async success => {
        if (!success) {
          return
        }

        try {
          await this.UPDATE_USER({
            id: this.$route.params.id,
            data: { password: this.password },
          })

          this.$_successToast()

          this.$nextTick(() => {
            this.$bvModal.hide('modal-password')
          })
        } catch (err) {
          this.$_errorToast('Error')
        }
      })
    },
  },
  mixins: [togglePasswordVisibility],
  components: {
    BModal,
    BButton,
    BForm,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },
}
</script>

<style></style>

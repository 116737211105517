<template>
  <div>
    <b-table
      :fields="fields"
      :items="GET_USER_PAYERS"
      show-empty
      :empty-text="$t('administration.no_records')"
    >
      <template #empty>
        <h4 class="text-center">{{ $t('administration.no_records') }}</h4>
      </template>
      <template #emptyfiltered="scope">
        <h4 class="text-center">{{ scope.emptyFilteredText }}</h4>
      </template>
      <template #head()="props">
        <span>{{ $t(props.label) }}</span>
      </template>

      <template #cell(attached_to_user)="row" class="text-center">
        <b-form-checkbox
          :checked="row.item.attached_to_user"
          @change="changeStatus($event, row.item.id)"
          name="check-button"
          class="custom-control-primary"
          switch
          inline
        >
        </b-form-checkbox>
      </template>
    </b-table>

    <!-- <div
      class="d-flex justify-content-between align-items-center flex-wrap px-1"
    >
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap">{{ $t('pagination.show_from') }}</span>
        <b-form-select
          v-model="params.page_size"
          :options="['5', '10', '15']"
          class="mx-1"
          @change="changePerPage"
        />
        <span class="text-nowrap"> {{ $t('pagination.lines') }} </span>
      </div>

      <b-pagination
        v-model="params.page"
        :total-rows="GET_USER_PAYERS.count"
        :per-page="params.page_size"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        @change="changePage"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div> -->

    <b-modal
      id="add-payer-modal"
      centered
      no-close-on-backdrop
      size="sm"
      title="Добавить плательщик "
      ok-title="Добавить"
    >
      <ValidationObserver ref="PayerRef">
        <BFormGroup>
          <validation-provider
            #default="{ errors }"
            name="плательщик "
            rules="required"
          >
            <label for="">
              Плательщик
            </label>
            <v-select
              :options="GET_PAYERS_LIST"
              :reduce="id => id.id"
              label="title"
              multiple
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </BFormGroup>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BButton,
  BFormSelect,
  BCard,
  BModal,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import VSelect from 'vue-select'
import AppSearchInput from '@/components/AppSearchInput.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCard,
    BModal,
    ValidationProvider,
    ValidationObserver,
    AppSearchInput,
    VSelect,
  },
  data() {
    return {
      params: {
        page: 1,
        // page_size: 10,
      },

      fields: [
        {
          label: 'Плательщик',
          key: 'title',
        },
        {
          label: this.$t('client.activity'),
          key: 'attached_to_user',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('knowledgeBase', ['GET_USER_PAYERS']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('knowledgeBase', ['FETCH_USER_PAYERS', 'UPDATE_USER_PAYER']),

    refresh() {
      this.FETCH_USER_PAYERS({ id: this.$route.params.id,  })
    },

    changeStatus(event, payer_id) {
      // console.log('ddd')
      this.UPDATE_USER_PAYER({
        id: payer_id, 
        data: {
          user_id: this.$route.params.id,
          attached_to_user: event,
        },
      }).then(() => {
        this.refresh()
      })
    },

    changePage(value) {
      this.params.page = value
      this.refresh()
    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.refresh()
    },
  },
}
</script>

<template>
  <div>
    <!-- {{ GET_USER }} -->
    <validation-observer ref="UserValidationRef">
      <b-row>
        <b-col cols="3">
          <b-card>
            <div class="d-flex justify-content-center">
              <b-avatar
                :src="dataAvatar"
                rounded
                size="250px"
                class="mx-auto"
              />
            </div>

            <div class="d-flex mt-2 flex-wrap">
              <b-form-file
                :drop-placeholder="$t('upload')"
                :browse-text="$t('upload')"
                :placeholder="$t('choose_file_or_drop')"
                @input="changeImg"
              />
              <b-button
                class="ml-auto mt-1"
                variant="danger"
                @click="deletePhoto"
                v-if="dataAvatar"
              >
                <feather-icon icon="TrashIcon" />
                {{ $t('delete') }}
              </b-button>
            </div>
          </b-card>
        </b-col>

        <b-col cols="6">
          <b-card>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  :label="$t('administration.login')"
                  label-for="phone-number"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('administration.login')"
                    rules="required"
                  >
                    <b-form-input v-model="formData.username" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6" v-if="!this.$route.params.id">
                <b-form-group>
                  <label for="v-password-new-user">{{
                    $t('user.password')
                  }}</label>

                  <validation-provider
                    #default="{ errors }"
                    :name="$t('user.password')"
                    rules="required|min:4"
                  >
                    <b-input-group
                      :class="errors.length ? 'is-invalid' : null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="v-password-new-user"
                        v-model="formData.password"
                        :state="errors.length ? false : null"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        name="v-password-new-user"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6" v-if="this.$route.params.id">
                <b-form-group :label="$t('user.password')" label-for="password">
                  <b-input-group>
                    <b-form-input placeholder="············" readonly />
                    <b-input-group-append>
                      <b-button
                        v-b-modal.modal-password
                        variant="outline-primary"
                      >
                        {{ $t('change') }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col v-if="$can('update', 'auth/group')" cols="12">
                <b-form-group :label="$t('user.role')" label-for="Role">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('user.role')"
                    rules="required"
                  >
                    <v-select
                      id="user_view_role"
                      v-model="formData.role"
                      :get-option-label="v => v.name[getLang] || ''"
                      :options="GET_ROLES.results"
                      :reduce="role => role.id"
                      class="style-chooser"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('theme')" label-for="Theme">
                  <v-select
                    v-model="formData.theme"
                    :options="themeOptions"
                    :reduce="item => item.value"
                    label="text"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="$can('update', 'auth/group')" md="6">
                <b-form-group :label="$t('status')" label-for="status">
                  <b-form-checkbox
                    v-model="formData.is_active"
                    class="mt-50"
                    inline
                    name="check-button"
                    switch
                  >
                    {{
                      formData.is_active
                        ? $t('task_manager.active')
                        : $t('task_manager.not_active')
                    }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="3">
          <b-card>
            <b-form-group>
              <label for="">{{ $t('administration.name') }} : </label>
              <b-form-input
                v-model="formData.first_name"
                :placeholder="$t('administration.name')"
              />
            </b-form-group>
            <b-form-group>
              <label for="">{{ $t('administration.surname') }} : </label>
              <b-form-input
                v-model="formData.last_name"
                :placeholder="$t('administration.surname')"
              />
            </b-form-group>
            <b-form-group>
              <label for="">Телеграмм : </label>

              <validation-provider
                #default="{ errors }"
                name="Телеграмм "
                rules="min:5"
              >
                <b-form-input
                  class="form-control"
                  v-model="formData.tg_username"
                  placeholder="Телеграмм "
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <label for="">Email: </label>

              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
              >
                <b-form-input
                  v-model="formData.email"
                  type="email"
                  placeholder="username@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>

      <div class="mb-1 d-flex justify-content-end align-items-end ">
        <b-button
          class="mr-2"
          variant="danger"
          @click="
            $router.push({
              name: 'users',
            })
          "
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button
          :variant="$route.params.id ? 'warning' : 'success'"
          @click="onSubmit"
        >
          {{ $route.params.id ? $t('change') : $t('save') }}
        </b-button>
      </div>
    </validation-observer>

    <BCard no-body class="mt-5 py-1" v-if="this.$route.params.id">
      <ContractorsTable :items="GET_USER.payers" />
    </BCard>
    <ModalPassword />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BRow,
  BBadge,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ModalPassword from './components/PasswordModal.vue'
import VSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.uz'
import { min, max, required, email, integer } from '@validations'
import i18n from '@/libs/i18n'
import ContractorsTable from './components/ContractorsTable.vue'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

extend('confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: "Ошибка совпадения пароля",
})

export default {
  data() {
    return {
      min,
      max,
      required,
      email,
      dataAvatar: '',
      integer,

      formData: {
        email: '',
        tg_username: null,
        username: '',
        password: '',
        role: [],
        is_active: true,
        theme: 'dark',
        language: 'ru',
        first_name: '',
        last_name: '',
        photo: null,
      },

      show_overlay: false,
      themeOptions: [
        { text: this.$t('general.light'), value: 'light' },
        { text: this.$t('general.dark'), value: 'dark' },
      ],
      status_options: [
        {
          title: 'Active',
          value: 1,
        },
        {
          title: 'Inactive',
          value: 0,
        },
      ],

      language_options: [
        { title: 'ru', name: 'Русский' },
        { title: 'uz', name: 'O`zbek' },
      ],
    }
  },

  computed: {
    ...mapGetters('users', [
      'GET_THEMES',
      'GET_ROLES',
      'GET_LANGS',
      'GET_USER',
    ]),

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    getLang() {
      return this.$i18n.locale || 'ru'
    },
  },

  async created() {
    this.loading = true
    if (!this.GET_ROLES.results.length) {
      await this.FETCH_ROLES()
    }

    if (this.$route.params.id) {
      const response = await this.FETCH_ONE_USER({
        id: this.$route.params.id,
      })

      const {
        photo,
        username,
        password,
        email,
        role,
        is_active,
        theme,
        language,
        first_name,
        last_name,
        tg_username,
      } = response

      this.formData.email = email || ''

      this.dataAvatar = photo
      this.formData.tg_username = tg_username
      this.formData.username = username
      this.formData.role = [...role]
      this.formData.is_active = is_active
      this.formData.theme = theme
      this.formData.language = 'ru'
      this.formData.first_name = first_name
      this.formData.last_name = last_name
    }
  },

  methods: {
    ...mapActions('users', [
      'UPDATE_USER',
      'FETCH_ONE_USER',
      'FETCH_ROLES',
      'CREATE_USER',
    ]),

    async onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()

      const res = await this.$refs.UserValidationRef.validate()

      if (!res) {
        return
      }

      let ACTION = this.$route.params.id ? this.UPDATE_USER : this.CREATE_USER

      if (!this.formData.photo) {
        delete this.formData.photo
      }

      if (!this.formData.tg_username) {
        this.formData.tg_username = ''
      }

      try {
        await ACTION({
          data: this.$_toFormData(this.formData),
          id: this.$route.params.id,
        })
        this.$_successToast(this.$t('general.success'))

        if (this.$route.params.id == getUserData().id) {
          useJwt.logout()
        } else {
          this.$router.replace('/users')
        }
      } catch (error) {
        console.log(error)
        this.$_errorToast(this.$t('general.error'))
      }
    },

    async changeImg(e) {
      this.formData.photo = e
      this.dataAvatar = await this.$_convertToBase64(e)
    },

    async deletePhoto() {
      const response = await this.$_showAreYouSureModal()

      if (!response) {
        return
      }

      this.formData.photo = null
      this.dataAvatar = null

      if (this.$route.params.id) {
        try {
          if (response) {
            await this.UPDATE_USER({
              data: { photo: null },
              id: this.$route.params.id,
            })
            this.$_successToast()
          }
        } catch (err) {
          this.loading = false
          this.$_errorToast('Error')
        }
      }
    },
  },

  components: {
    BCard,
    ModalPassword,
    BButton,
    Cleave,
    BRow,
    BCol,
    BAvatar,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormFile,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    VSelect,
    ContractorsTable,
    Cleave,
  },
  mixins: [togglePasswordVisibility],
}
</script>

